<template>
  <div class="section-content-wrapper">
    <div class="visitor-section-header">
      {{ $translate('groupSelectionHeading') }}
    </div>
    <div
      v-for="group in sortedGroups"
      :key="group.id"
      class="group-item mb-6"
      aria-roledescription="button"
      aria-label="group select item"
      @click="selectGroup(group)"
    >
      {{ $translate(group.name) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IGroup, VisitFormSectionType } from '@einfachgast/shared';
import { useGroupsStore } from '@/store/groups';
import { dynamicFormService } from '@/dynamic-form/dynamic-form-service';
import router from '@/router';
import { useRoute } from 'vue-router';
import { RouteName } from '@/router/route-name';
import { computed } from 'vue';

const route = useRoute();
const groupsStore = useGroupsStore();

const selectGroup = (group: IGroup) => {
  groupsStore.selectGroup(group);
  const nextStep = dynamicFormService.getNextStep(route.params.step as VisitFormSectionType);
  router.push({ name: RouteName.Checkin, params: { step: nextStep.type } });
};
// get groups array sorted by sortOrder property
const sortedGroups = computed(() => [...groupsStore.availableGroups].sort((a, b) => a.sortOrder - b.sortOrder));
</script>
<style lang="scss" scoped>
  .visitor-section-header {
    margin-bottom: 16px;
    text-align: center;
  }
  .group-item {
    box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.1rem;
    word-wrap: break-word;
  }
</style>

